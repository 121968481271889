
import { Component, Prop, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'SidebarLogo'
})
export default class extends Vue {
  @Prop({ required: true }) private collapse!: boolean

  userStore: any = useUserStore()

  get title() {
    return this.userStore.model.manageable.name
  }

  get userLogo() {
    return this.userStore.model.manageable.logo
  }

  get roles() {
    return this.userStore.roles
  }
}
