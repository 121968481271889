
import { Component, Vue } from 'vue-property-decorator'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'

@Component({
  name: 'Navbar',
  components: {
    Breadcrumb,
    Hamburger,
    LangSelect: () => import('@/components/LangSelect/index.vue')
  }
})
export default class extends Vue {
  public appStore: any = useAppStore()
  public userStore: any = useUserStore()

  get sidebar() {
    return this.appStore.sidebar
  }

  get device() {
    return this.appStore.device.toString()
  }

  private toggleSideBar() {
    this.appStore.toggleSideBar(false)
  }

  private async signout() {
   await this.userStore.signout()
  }

  private async signoutMarketAndWalleoAccount() {
    await this.userStore.signoutRedirect()
  }
}
