
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { useAppStore, DeviceType } from '@/stores/app'
import { AppMain, Navbar, Sidebar } from './components'
import ResizeMixin from './mixin/resize'

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar
  }
})
export default class extends mixins(ResizeMixin) {
  public appStore: any = useAppStore()

  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    }
  }

  get fixedHeader() {
    return false
  }

  private handleClickOutside() {
    this.appStore.closeSideBar(false)
  }
}
