
import { Component, Vue } from 'vue-property-decorator'
import { useAppStore } from '@/stores/app'
import { usePermissionStore } from '@/stores/permission'
import SidebarItem from './SidebarItem.vue'
import SidebarLogo from './SidebarLogo.vue'
import variables from '@/styles/variables.module.scss'


@Component({
  name: 'SideBar',
  components: {
    SidebarItem,
    SidebarLogo
  }
})
export default class extends Vue {
  public appStore: any = useAppStore()
  public permissionStore: any = usePermissionStore()

  get sidebar() {
    return this.appStore.sidebar
  }

  get routes() {
    return this.permissionStore.routes
  }

  get showLogo() {
    return true
  }

  get menuActiveTextColor() {
    return variables.menuActiveText
  }

  get variables() {
    return variables
  }

  get activeMenu() {
    const route = this.$route
    const { meta, path } = route
    // if set path, the sidebar will highlight the path you set
    if (meta?.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  get isCollapse() {
    return !this.sidebar.opened
  }
}
